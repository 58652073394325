import axios from 'axios'

const token = 'ca-pd_kIKEHzpaeZHthqgH:APA91bFyQ4YdtrPaaR-yerKAtbegI77URPqcfT2wZUVWSao77ZW4gN2ek2ypSJezA6qofLQJAUk2TpcPagb3dpKYbmFGq752H-Pir7Nbc3xa0ao0EvtVoUOfxcV5m91WeN5Vw2lZR84W'
export const Message = axios.create({
    baseURL: `https://fcm.googleapis.com/fcm`,
    headers: {
        Accept: "Content-Type: application/json",
        Authorization: `Key=${token}`,
    }
})

