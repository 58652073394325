import React, { useState } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Colors from '../shared/Colors';
import { tranzSale } from '../api/PowerTranz'
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import moment from 'moment'
import { serverUrl } from '../api/constants'
import { FaWindowClose } from 'react-icons/fa';
import m_v_logo from '../assests/visa-and-mastercard-logo.png'


const PaymentsModal = ({ show, close, amount, action }) => {

  const user = useSelector(state => state.user)
  const [cardNum, setCardNum] = useState('')
  const [cardName, setCardName] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [cvv, setCvv] = useState('')
  const [message, setMessage] = useState('')
  const navigate = useNavigate();
  const dispatch = useDispatch()


  const handleClose = () => {
    close()
  }

  const pay = async () => {
    let data = JSON.stringify({
      "TransacctionIdentifier": uuidv4(),
      "TotalAmount": amount,
      "CurrencyCode": "840",
      "ThreeDSecure": true,
      "OrderIdentifier": `Ord-${moment(new Date()).unix()}`,
      "Source": {
        "CardPan": cardNum,
        "CardCvv": cvv,
        "CardExpiration": `${year}${month}`,
        "CardholderName": cardName
      },
      "BillingAddress": {
        "FirstName": user?.data.firstName,
        "LastName": user?.data.lastName,
        "EmailAddress": user?.data.email,
        "PhoneNumber": user?.data.phone
      },
      "AddressMatch": false,
      "browserJavaEnabled": false,
      "ExtendedData": {
        "ThreeDSecure": {
          "ChallengeWindowSize": 4,
          "ChallengeIndicator": "01"
        },
        "MerchantResponseUrl": `${serverUrl}/approved-transaction`
      }
    });
    action()
    await tranzSale(data, dispatch)
    navigate('/thank-you');
  };


  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}

      >
        <div style={{ heigh: '90vh', margin: 20, overflow: 'scroll' }}>
          <div style={{ position: 'absolute', right: 15, top: 10 }}>
            <FaWindowClose size={30} onClick={() => handleClose()} color='black' style={{ marginTop: 10 }} />
          </div>

          <h2 style={{ fontWeight: 'bold' }}>Payment Details</h2>

          <p style={{ color: 'red' }}>{message}</p>
          <h4 style={{ marginTop: 10, fontWeight: 'bold' }}>Card Details</h4>
          <Form.Label htmlFor="firstName">Card Number</Form.Label>
          <Form.Control
            type="text"
            value={cardNum}
            id="firstName"
            onChange={(value) => { setCardNum(value.target.value); setMessage('');}}
          />

          <Form.Label htmlFor="lastName" style={{ marginTop: 10 }}>Name on Card</Form.Label>
          <Form.Control
            type="text"
            value={cardName}
            id="lastName"
            onChange={(value) => { setCardName(value.target.value); setMessage(''); }}
          />

          <Row style={{ marginTop: 10 }}>
            <Col md={6} >
              <Form.Label htmlFor="expire">Expire Date</Form.Label>
              <Row>
                <Col md={6} >

                  <Form.Control
                    type="text"
                    maxLength={2}
                    placeholder='MM'
                    value={month}
                    id="expire"
                    onChange={(value) => { setMonth(value.target.value); setMessage(''); }}
                  />
                </Col>
                <Col md={6} >
                  <Form.Control
                    type="text"
                    maxLength={2}
                    placeholder='YY'
                    value={year}
                    id="expire"
                    onChange={(value) => { setYear(value.target.value); setMessage('');}}
                  />
                </Col>
              </Row>

            </Col>
            <Col md={6} >
              <Form.Label htmlFor="inputPassword5">CVV</Form.Label>
              <Form.Control
                type="password"
                value={cvv}
                placeholder='***'
                maxLength={5}
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                onChange={(value) => { setCvv(value.target.value); setMessage(''); }}
              />
            </Col>
          </Row>
          <div style={{ marginTop: 20 }}>

            <Row>
              <Col md={12}>
                <Button
                  disabled={cardNum === '' || cardName === '' || year === '' || month === '' || cvv === ''}
                  style={{
                    width: '100%',
                    backgroundColor: Colors.primary,
                    borderColor: Colors.primary
                  }}
                  variant="success"
                  onClick={() =>
                    pay()
                  }
                >
                  Pay Now
                </Button>
              </Col>
            </Row>

          </div>
          <div className='text-center' style={{ margin: '30px 0' }}>
            <img src={m_v_logo} style={{ width: '70%' }} />
          </div>
        </div>



      </Modal>
    </div>
  )
}

export default PaymentsModal