
import { setRedirectData } from '../redux/actions';

import { serverUrl } from './constants';

export const tranzSale = (data, dispatch) => {
    fetch(`${serverUrl}/fac-sale`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ data }),
        })
        .then((res) => res.json())
        .then((data) => {
            dispatch(setRedirectData(data.RedirectData))
        }
        );

}

