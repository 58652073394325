import React, { useEffect, useRef, useState } from 'react'
import { firestore, auth } from '../util/firebase';
import { collection, getDocs, query, where, addDoc, doc, updateDoc, onSnapshot, deleteDoc, Timestamp, } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { setCheckoutDetails, getCartItems, selectedExcursion, getBookings, getOrders } from '../redux/actions';
import Colors from '../shared/Colors';
import logo from '../assests/logo.png'
import { Message } from '../api/Functions';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { serverUrl } from '../api/constants';



const ThanksYou = (props) => {


    const checkoutDetails = useSelector(state => state.checkoutDetails)
    const id = useSelector(state=>state.selectedId)
    const [cashout, setCashout] = useState(1)

    const resData = useSelector(state => state.redirectData)

    const dispatch = useDispatch()
    const ref = useRef()
    const [paymentAuth, setPaymentAuth] = useState(false)
    const [payFailed, setPayFailed] = useState(false)

    // const sendNotification = (user) => {
    //     Message.post('/send', {
    //         notification: {
    //             title: `New Booking`,
    //             body: `${user}, sumitted a new Booking Rad Venture's Webite`,
    //             sound: "default"
    //         },
    //         to: '/topics/Notifications',
    //         priority: "high"
    //     })

    // }

    const updateBooking = async () => {
        await updateDoc(doc(firestore, 'bookings', id), {
            status: 'NEW'
        })
            sendEmail()
        }


    const deleteBooking = async()=>{
        await deleteDoc(doc(firestore,'bookings', id))
    }


    const handleOrder = async () => {
        const coll = collection(firestore, 'orders')
        await addDoc(coll, checkoutDetails.data).then(() => {
            checkoutDetails.data.order?.forEach(async (item) => {

                await deleteDoc(doc(firestore, 'carts', item?.id))
                const coll1 = collection(firestore, 'products')

                const prod = await getDocs(coll1, item?.data.productId)
                await updateDoc(doc(firestore, 'products', item?.data.productId), {
                    sold: parseInt(prod.docs[0].data().sold) + parseInt(item?.data.quantity)
                })
            })
        }).then(() => {
            dispatch(getOrders(auth.currentUser.uid))
            dispatch(getCartItems(auth.currentUser.uid))
            dispatch(setCheckoutDetails(null))

        })
    }

    window.addEventListener('message', function (event) {
        if (event.data.event_id === "payment_successfull") {
            setPaymentAuth(true)
            updateBooking()
        } else if (event.data.event_id === "payment_failed") {
            setPayFailed(true)
            deleteBooking()
        }
    });

    useEffect(() => {
        if (checkoutDetails && cashout === 1 && paymentAuth) {
            if (checkoutDetails.type === 'cart') {
                handleOrder()
                setCashout(0)
            }
            if (checkoutDetails.type === 'booking') {
                // updateBooking()
                setCashout(0)
            }

        }
    }, [paymentAuth, cashout])

    new Date().toDateString()
    const sendEmail = () => {
        const data = {
            name: checkoutDetails.data.name,
            amount: checkoutDetails.data.total,
            date: checkoutDetails.date.toDateString(),
            excursion: checkoutDetails.data.excursionName,
            time: checkoutDetails.data.time,
            quantity: checkoutDetails.data.quantity,
            email: checkoutDetails.data.email,
            pickupDetails: checkoutDetails.data.pickupDetails,
            location: checkoutDetails.data.pickupTown,
            pickupTime: checkoutDetails.data.pickupTime
        }
        fetch(`${serverUrl}/send-mail`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ data }),
            })
            .then((res) => res.json())
            .then((data) => {
                // console.log('Response', data)
            }
            );
    }



    if (paymentAuth) {
        return (
            <div style={{ minHeight: '60vh', padding: '20vh 0', textAlign: 'center' }}>

                <img src={logo} style={{ width: 200, marginBottom: 30 }} />
                <h1 style={{ textAlign: 'center', fontWeight: 'bold', color: Colors.primary }}>Thank You</h1>
                <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    We appreciate your business
                </h4>
            </div>
        )
    }
    if (payFailed) {
        return (
            <div style={{ minHeight: '60vh', padding: '20vh 0', textAlign: 'center', backgroundColor: '' }}>

                <img src={logo} style={{ width: 200, marginBottom: 30 }} />
                <h1 style={{ textAlign: 'center', fontWeight: 'bold', color: Colors.secondary }}>Payment Failed</h1>
                <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    Please check card details and retry the transaction!
                </h4>
            </div>
        )
    }
    return (
        <div style={{ minHeight: '30vh', padding: '5%', textAlign: 'center', backgroundColor: '#fbfbfb' }}>
            <div class="text-center">
                <h4 class="display-4">Authenticate Payment</h4>
                <iframe id='fac-3ds2-frame' rameborder="0"
                    style={{
                        width: '100%', height: 500,
                        backgroundImage: `url(${require("../assests/loading.gif")})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center'
                    }}
                    srcDoc={JSON.stringify(resData)}
                />
            </div>
        </div>
    )
}

export default ThanksYou
